#start-map {

  .switch-container {
    display: flex;
    padding-top: $spacing-1;
  }

  .switch-container label {
    margin-left: $spacing-1;
    margin-right: $spacing-1;
  }

  .switch-container span {
    cursor: pointer;
  }

  .switch-container input[type="checkbox"].switch {
    position: absolute;
    opacity: 0;
  }

  .switch-container input[type="checkbox"].switch + div.switch-background {
    vertical-align: middle;
    width: 40px;
    height: 20px;
    border-radius: 999px;
    background-color: $blue;
    cursor: pointer;
    transition-timing-function: cubic-bezier(1,0,0,1);
    transition-duration: 0.4s;
    transition-property: all;
  }

  .switch-container input[type="checkbox"].switch + div.switch-background > div.switch-button {
    float: left;
    width: 18px;
    height: 18px;
    border-radius: inherit;
    background: $white;
    transition-timing-function: cubic-bezier(1,0,0,1);
    transition-duration: 0.4s;
    transition-property: transform;
    pointer-events: none;
    margin-top: 1px;
    margin-left: 1px;
  }

  .switch-container input[type="checkbox"].switch:checked + div.switch-background {
    background-position: 0 0;
  }

  .switch-container input[type="checkbox"].switch:checked + div.switch-background > div.switch-button {
    transform: translate3d(20px, 0, 0);
  }

  .switch-container input[type="checkbox"].switch:focus + div.switch-background {
    box-shadow: 0 0 0 2px $focus;
  }
}