@media only screen and (max-width : 1024px) {
  #map-root, #start-map {
    width: 100vw;
    height: 100%;
    height: -webkit-fill-available;
    position: fixed;
    font-size: 1em;
    display: flex;
    flex-direction: column;

    .splash-background {
      z-index: 9;
    }

    #toggle-splash {
      height: 30px;
      width: 30px;
      font-size: 1em;
      top: 9px;
      right: 45px;
      left: unset;
    }

    #splash {
      width: calc(100% - 40px);
      height: calc(100% - 120px);
      margin: 60px auto;
      box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.20);
      border-radius: 4px;
      position: relative;
      min-width: unset;

      .content {
        padding: 0 20px;
      }

      h1.title {
        font-size: 1.75em;
      }
    }

    .splash-container {
      width: 100%;
      height: 100%;
      height: -webkit-fill-available;
      position: absolute;
      top: 0;
      left: 0;
    }

    .splash p {
      font-size: 1em;
    }

    .splash {
      height: 95%;
      width: 90vw;
      transform: translate(5vw, 2vh);
      overflow: scroll;
    }

    .splash .btn {
      font-size: 1em;
    }

    .splash-btn {
      height: auto;
      margin: auto;
    }

    #app-wrapper {
      display: flex;
      flex-direction: column;

      aside, header {
        flex: 0 1 auto;
      }

      main {
        flex: 1 1 auto;
        position: relative;
      }
    }

    .map-container {
      /*position: relative;*/
      display: block;
      width: 100%;
      height: 100%;
      transition: all 0.3s cubic-bezier(1,0,0,1);
    }

    .list-container {
      display: block;
      position: absolute;
      background: $white;
      width: 100%;
      padding: $spacing-2 $spacing-4;
      overflow: auto;
      bottom: 0;
      top: 0;
      margin: 0;
      transition: all 0.3s cubic-bezier(1,0,0,1);
      z-index: 1;
    }

    main {
      flex: 1 1 auto;
      position: relative;

      &.view-map {
        .list-container {
          left: -100%;
          bottom: 0;
          top: 0;
        }
      }

      &.view-list {
        .list-container {
          left: 0;
          bottom: 0;
          top: 0;
        }
      }
    }

    .filter-form {
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $white;
      flex-direction: column;
      align-items: center;
      top: 0;
      bottom: 0;
    }

    .detail{
      position: absolute;
      bottom: 0;
      top: 0;
      background-color: $white;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-left: $spacing-4;
      padding-right: $spacing-4;
    }

    .banner-wrapper {
      .beta-banner {
        border-width: 0 70px 70px 0;
        border-color: transparent $blue transparent transparent;
        right: 0;
        z-index: 10;

        .banner-text {
          transform: translate(33px, 15px) rotate(45deg);
        }
      }
    }

    #mobile-map-popup {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      height: auto;
      max-height: 30vh;
      bottom: 0;
      margin: $spacing-4;
      background-color: $white;
      border: 1px $light-grey solid;
      box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.20);
      @include focus-border;
    }

    .popup-pic {
      width: 30%;
      max-width: 300px;
      height:auto;
      max-height: 25vh;
      overflow: hidden;
      display: inline-block;
      margin: $spacing-1;
    }

    .popup-txt {
      display: inline-block;
      height: 100%;
    }


    #detail-text{
      margin-bottom: $spacing-8;
      margin-top: $spacing-8;
    }

    .detail .back-to-list #back {
      margin: auto;
    }

    .filter-wrap .back-to-list {
      margin: 0 auto;
      margin-bottom: $spacing-2;
      width: auto;
    }

    .back-to-list {
      margin-top: $spacing-4;
    }

    #filter {
      display: block;
      position: relative;
      float: right;
    }

    .logo-wrap {
      display: flex;
      flex-direction: column;
      position: absolute;
      margin: .5em;
      align-items: center;
      text-align: center;
      border-radius: 5%;
      padding: .5em;
      width: 25vw;
      height: auto;
    }

    .logo {
      padding: 0;
      margin: 0;
      height: 3em;
    }

    header .logo {
      font: 1.2em sans-serif;
      color: $blue;
    }

    .filter-menu {
      position: absolute;
      background: $white;
      bottom: 49px;
      left: 0;
      width: 100%;
      transition: all 0.3s ease;
      height: 0%;
      overflow: hidden;
      box-shadow: -2px -2px 4px $medium-grey;

      &.mobile-open {
        height: 387px;
      }
    }

    .filter-wrap {
      padding: $spacing-5;
      overflow: auto;
    }

    #list {
      display: block;
      position: relative;
      margin: auto;
    }

    #mapview, #listview {
      line-height: 0em;
    }

    .detail .btn{
      margin: auto;
    }

    .logo-wrap-detail-mobile img {
      width: 100px;
      height: auto;
      margin: auto;
    }

    .logo-wrap-detail-mobile header .program-name {
      font-size: 1.5em;
    }

    .logo-wrap-detail-mobile{
      margin: .5em auto;
    }

    #filter-marker {
      background: $red;
      border-radius: 50%;
      z-index: 1;
      height: $spacing-3;
      width: $spacing-3;
      position: absolute;
      right: -5px;
      top: -5px;
    }

    #map-legend {
      display: none;
    }
  }
}


@media only screen and (max-width : 700px) {
  #start-map {
    .splash h1 {
      font-size: 1.5em;
    }
    .splash h3 {
      font-size: 1.3em;
    }
    .splash h6 {
      font-size: 1.2em;
    }
    .splash p {
      font-size: 1em;
    }
    .splash-head {
      flex: 1.5;
      max-width: 80%;
    }
    .splash-body {
      font-size: 1em;
    }
  }
}

@media only screen and (max-width : 450px) {
  body {
    width: 100%;
    height: 100%;
  }

  #start-map {

    .logo {
      width: 20vw;
      height: auto;
    }
    header .logo {
      font-size: 1.5em;
    }
    .detail .btn {
      margin: auto;
    }
    .splash{
      overflow: scroll;
    }
    .splash-body {
      font-size: 1.5em;
      flex: 2;
    }
    .splash-head{
      flex: 0.8;
    }
    .splash-button-wrap{
      flex: 0.5;
    }
    .splash h1 {
      font-size: 1.5em;
    }
    .splash h3 {
      font-size: 1.3em;
    }
    .splash h6 {
      font-size: 1.2em;
    }
    .splash .btn {
      font-size: 1em;
    }
  }
}

@media only screen and (max-width: 350px) {
  #start-map {
    .splash-head, .splash-body, .splash-button-wrap {
      display: block;
    }

    .splash-head, .splash-button-wrap {
      margin: 1em auto;
    }

    #geolocate-btn {
      margin: $spacing-2;
      top: 0;
    }

    #mobile-map-popup{
      margin: $spacing-2;
    }
  }
}

@media only screen
  and (min-device-width: 350px)
  and (max-device-width: 767px)
  and (-webkit-min-device-pixel-ratio: 2) {

  #start-map {

    .splash-head{
      flex: .5;
    }

    .splash-button-wrap {
      flex: 0.5;
    }

    #geolocate-btn {
      margin: $spacing-2;
    }

    #mobile-map-popup {
      margin: $spacing-2;
    }
  }
}

@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px) {

  #start-map {
    .splash-body {
      font-size: 1.5em;
    }

    .splash h6 {
      font-size: 1.8em;
    }

    .splash .btn {
      font-size: 2em;
    }
  }
}

