

@for $i from -10 through 10 {
  $margin: $spacing-base * $i;
  .m-#{$i} {
    margin: $margin + 0px;
  }
}

@for $i from -10 through 10 {
  $margin: $spacing-base * $i;
  .ml-#{$i} {
    margin-left: $margin + 0px;
  }
}

@for $i from -10 through 10 {
  $margin: $spacing-base * $i;
  .mr-#{$i} {
    margin-right: $margin + 0px;
  }
}

@for $i from -10 through 10 {
  $margin: $spacing-base * $i;
  .mb-#{$i} {
    margin-bottom: $margin + 0px;
  }
}

@for $i from -10 through 10 {
  $margin: $spacing-base * $i;
  .mt-#{$i} {
    margin-top: $margin + 0px;
  }
}

@for $i from -10 through 10 {
  $padding: $spacing-base * $i;
  .p-#{$i} {
    padding: $padding + 0px;
  }
}

@for $i from -10 through 10 {
  $padding: $spacing-base * $i;
  .pl-#{$i} {
    padding-left: $padding + 0px;
  }
}

@for $i from -10 through 10 {
  $padding: $spacing-base * $i;
  .pr-#{$i} {
    padding-right: $padding + 0px;
  }
}

@for $i from -10 through 10 {
  $padding: $spacing-base * $i;
  .pb-#{$i} {
    padding-bottom: $padding + 0px;
  }
}

@for $i from -10 through 10 {
  $padding: $spacing-base * $i;
  .pt-#{$i} {
    padding-top: $padding + 0px;
  }
}

.display {
  &-flex {
    display: flex;
  }
  &-block {
    display: block;
  }
  &-inline-flex {
    display: inline-flex;
  }
  &-inline-block {
    display: inline-block;
  }
  &-none {
    display: none;
  }
}

.justify {
  &-center {
    justify-content: center;
  }
  &-left {
    justify-content: flex-start;
  }
  &-right {
    justify-content: flex-end;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
}

.align {
  &-center {
    align-items: center;
  }
  &-left {
    align-items: flex-start;
  }
  &-right {
    align-items: flex-end;
  }
  &-baseline {
    align-items: baseline;
  }
}


.position {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
  &-fixed {
    position: fixed;
  }
}

.bg {
  &-blue {
    background-color: $blue;
  }
  &-red {
    background-color: $red;
  }
  &-yellow {
    background-color: $yellow;
  }
  &-green {
    background-color: $green;
  }
  &-dark {
    background-color: $black;
  }
  &-light {
    background-color: $light-grey;
  }
  &-white {
    background-color: $white;
  }
  &-light-blue {
    background-color: $light-blue;
  }
  &-light-red {
    background-color: $light-red;
  }
  &-light-yellow {
    background-color: $light-yellow;
  }
  &-light-green {
    background-color: $light-green;
  }
}

.text {
  &-blue {
    color: $blue;
  }
  &-red {
    color: $red;
  }
  &-yellow {
    color: $yellow;
  }
  &-green {
    color: $green;
  }
  &-black {
    color: $black;
  }
  &-white {
    color: $white;
  }
  &-light {
    color: $light-grey;
  }
  &-muted {
    color: $medium-grey;
  }
  &-bold {
    font-weight: bold;
  }
  &-italic {
    font-style: italic;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }
  &-large {
    font-size: 1.2em;
  }
  &-small {
    font-size: 0.9em !important;
  }
  &-xs {
    font-size: 0.8em !important;
  }
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.shadow-depth {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);

}

@mixin focus-border {
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $focus;
  }
}

@keyframes delay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.delay-in {
  animation-name: delay-in;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}


